const SCROLL_ANIMATION_TRIGGER_CLASSNAME = 'scroll-trigger'
const SCROLL_ANIMATION_ACTIVE_CLASSNAME = 'scroll-trigger--active'

export function onIntersection(
	elements: IntersectionObserverEntry[],
	observer: IntersectionObserver
) {
	elements.forEach((element) => {
		if (element.isIntersecting) {
			const elementTarget = element.target
			if (!element) throw 'element target not found'
			elementTarget.classList.add(SCROLL_ANIMATION_ACTIVE_CLASSNAME)
			observer.unobserve(elementTarget)
		}
	})
}

export function initializeScrollAnimationTrigger(rootEl = document) {
	const animationTriggerElements = Array.from(
		rootEl.getElementsByClassName(SCROLL_ANIMATION_TRIGGER_CLASSNAME)
	)
	if (animationTriggerElements.length === 0) return

	const observer = new IntersectionObserver(onIntersection, {
		threshold: 0.1,
	})
	animationTriggerElements.forEach((element) => observer.observe(element))
}
